import React from "react"
import { graphql, StaticQuery } from "gatsby"

import HeaderImg from "../images/header4.jpg"

export default function Slideshow() {
  return (
    <StaticQuery
      query={graphql`
        {
          datoCmsImagenPrincipalInicio {
            frase
            foto {
              fluid {
                src
              }
            }
          }
        }
      `}
      render={data => (
        <div
          className="w-full bg-blue-200 min-h-1/2 text-center flex items-center"
          style={{
            backgroundImage: `url(${data.datoCmsImagenPrincipalInicio.foto.fluid.src})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
            backgroundPosition: "center",
          }}
        >
          <h1
            className="text-white mx-auto titles text-2xl md:text-5xl w-3/5"
            style={{ textShadow: "1px 2px 3px rgba(0,0,0,0.2)" }}
          >
            {data.datoCmsImagenPrincipalInicio.frase}
          </h1>
        </div>
      )}
    />
  )
}
