import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slideshow from "../components/slideshow"

import Footer from "../components/footer"

import Whatsapp from "../images/whatsapp_grey.svg"

export const query = graphql`
  {
    allDatoCmsServicio(sort: { order: ASC, fields: ordenDeServicio }) {
      nodes {
        titulo
        slug
        imagenDePortada {
          fluid {
            src
          }
        }
        descripcionDeInicio
      }
    }
    allDatoCmsBio {
      nodes {
        titulo
        bio
        foto {
          fluid {
            src
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Servicios de sanación energética, Reiki, Tarot y Consejería en Costa Rica" />

    <Slideshow />

    <div className="w-full mx-auto bg-gray-800 pt-10 pb-10 lg:pt-32 lg:pb-20">
      <div className="w-full lg:w-10/12 mx-auto">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-10">
          Servicios
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 service-row">
          {data.allDatoCmsServicio.nodes.map(servicio => (
            <div className="w-full pb-6 px-3" key={servicio.slug}>
              <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                <Link
                  className="text-center rounded-md mx-auto block"
                  to={`/${servicio.slug}/`}
                >
                  <div
                    className="bg-cover bg-center h-56 p-4"
                    style={{
                      backgroundImage: `url(${servicio.imagenDePortada.fluid.src})`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                </Link>
                <div className="transition-colors duration-500 ease-in-out p-4 hover:bg-gray-200">
                  <Link
                    to={`/${servicio.slug}/`}
                    className="block titles uppercase text-center tracking-wide text-lg font-bold text-gray-900 "
                  >
                    {servicio.titulo}
                  </Link>
                </div>
                <div className="flex p-4 border-t border-gray-300 text-gray-700">
                  <div className="flex-1 inline-flex items-center">
                    <div
                      className="texts descriptions text-gray-800"
                      dangerouslySetInnerHTML={{
                        __html: servicio.descripcionDeInicio,
                      }}
                    />
                  </div>
                </div>
                <div className="border-t border-gray-300 bg-gray-100 text-center ">
                  <Link
                    className="transition-colors duration-500 ease-in-out titles text-lg text-center rounded-md px-5 py-4 mx-auto block border-b border-grey-200 hover:bg-gray-400"
                    to={`/${servicio.slug}/`}
                  >
                    Quiero saber más
                  </Link>
                  <a
                    className="transition-colors duration-500 ease-in-out titles text-lg text-center rounded-md px-5 py-4 mx-auto block hover:bg-gray-300"
                    href={`https://api.whatsapp.com/send?phone=50688355826&text=Quiero%20una%20cita%20de%20${encodeURIComponent(
                      servicio.titulo
                    )}`}
                  >
                    Agendar una cita{" "}
                    <img
                      src={Whatsapp}
                      style={{ maxWidth: "23px", display: "inline-block" }}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    {/*Sobre*/}

    {data.allDatoCmsBio.nodes.map(bio => (
      <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-10">
          {bio.titulo}
        </h2>
        <div className="w-full lg:w-10/12 lg:flex mx-auto">
          <div className="w-full lg:w-1/3">
            <img
              src={bio.foto.fluid.src}
              alt={bio.titulo}
              className="p-3 rounded-md"
            />
          </div>

          <div className="w-full lg:w-2/3 mt-5 lg:mt-0 bio">
            <div dangerouslySetInnerHTML={{ __html: bio.bio }} />
          </div>
        </div>
      </div>
    ))}

    {/*Sobre*/}

    <Footer />
  </Layout>
)

export default IndexPage
